@mixin avatar($size) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  background-color: #f8f8f8;
  text-align: center;
  border-radius: 50%;

  .initials {
    font-size: $size*0.5;
    line-height: $size*0.5;
    color: #111;
  }
}

.avatar-circle      { @include avatar(100px); }
.avatar-circle-sm   { @include avatar(50px); }
.avatar-circle-xs   { @include avatar(30px); }
.avatar-circle-lg   { @include avatar(250px); }
